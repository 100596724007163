import React, { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { getUserInfo } from '../handlers/auth/authApi'
import { EPages as EContainerPages } from '../helpers/constant'
import '../helpers/polyfills/index'
import AppGlobalContext, { TAppGlobalContext } from '../packages/GlobalLayout/context'
import MasterLayout from '../packages/GlobalLayout/MasterLayout'
import { setRestrictRoutesAction } from '../packages/SearchResults/global/searchResultGlobalMiscActions'
import searchResultsStore from '../packages/SearchResults/searchResultsStore'
import { ELanguage } from '../packages/SearchResults/utils/language'
import RecommendJobs from '../packages/RecommendJobs'
import { EPages } from '@microfrontends/app-shell-v2'
import { HogProvider } from '@microfrontends/react-components'

const isOptOutUserAgentFilter = process.env.POSTHOG_OPT_OUT_USERAGENT_FILTER === '1'

export interface IError404PageProps {
    language: 'en' | 'vi'
    hiddenRoutes: []
}

const Error404Page = (props: IError404PageProps) => {
    const { hiddenRoutes = [] } = props
    const storedLanguage = searchResultsStore.getState().globalMisc.language
    // TODO: REPLACE old i18n.language
    const serverLanguage: ELanguage = storedLanguage || ELanguage.VI

    const [contextData, setContextData] = useState<TAppGlobalContext>({
        langCode: serverLanguage,
        user: null
    })

    useEffect(() => {
        searchResultsStore.dispatch(setRestrictRoutesAction(hiddenRoutes))

        const getUser = async () => {
            const userData = await getUserInfo('', '', false)

            setContextData({
                ...contextData,
                user: userData
            })
        }
        getUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const userId = contextData.user?.userId ?? 0

    return (
        <HogProvider
            posthogConfig={{
                key: process.env.POSTHOG_API_KEY,
                host: process.env.POSTHOG_API_HOST,
                options: {
                    debugMode: false,
                    optOutUserAgentFilter: isOptOutUserAgentFilter
                }
            }}
            >
            <Provider store={searchResultsStore}>
                <AppGlobalContext.Provider value={contextData}>
                    <MasterLayout
                        pageName={EPages.NONE}
                        user={contextData.user as any}
                        language={serverLanguage}
                        hiddenRoutes={hiddenRoutes}
                        navigateSearch
                        cornerColor='#f8f9fa'
                    >
                        <RecommendJobs
                            langCode={serverLanguage}
                            userId={userId}
                            pageName={EContainerPages.ERROR404}
                            className='error404-wrapper'
                            title='Sorry. The page you are looking for might have been removed or temporarily unavailable.'
                        />
                    </MasterLayout>
                </AppGlobalContext.Provider>
            </Provider>
        </HogProvider>
    )
}

export default Error404Page
